import React, { useState } from "react";
import { makeStyles, Box, IconButton, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { localeFunction } from "../../utils";
import { NormalPdfOpen } from "../../utils/sessionStorage";

const useStyles = makeStyles((theme) => ({
  underlineText: {
    width: "100%",
    color: "#000",
    textDecoration: "none",
    transition: "0.5s ease-in-out",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer !important",
    gap: "15px",
    "& :hover": {
      textDecoration: "underline",
      color: "#0192be",
    },
    "& h3": {
      lineHeight: "23px",
      fontWeight: 400,
      fontFamily: "system-ui",
      width: "200px",
    },
    "& button": {
      width: "35px",
      height: "35px",
      color: "#FFF",
      background: "#06485b",
    },
  },
  root: {
    width: "100%",
    "@media(max-width:375px)": {
      overflowY: "scroll",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  mainCard_section: {
    padding: "5px 0px 0px 16px",
  },
  ListtopTitle: {
    textTransform: "uppercase",
    padding: "5px 0px 0px 16px",
  },
  AcchivTitle: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    width: "100%",
    borderBottom: "1px solid #ccc",
    paddingBottom: "10px",

    "@media(max-width:375px)": {
      overflowX: "scroll",
    },
    "@media(max-width:1024px)": {
      overflowX: "scroll",
    },
  },
  statedata: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  leftContent: {
    width: "20%",
    "@media(max-width:1024px)": {
      width: "initial",
    },
    "& h3": {
      "@media(max-width:599px)": {
        fontSize: "13px",
      },
    },
  },
  rightContent: {
    width: "80%",
    "@media(max-width:1024px)": {
      width: "initial",
    },
    "& h3": {
      "@media(max-width:599px)": {
        fontSize: "13px",
      },
    },
  },
  arciveListTitle: {
    borderRadius: "0px",
    padding: "4px 16px",
    boxShadow: "none",
  },
  viewmoreBtn: {
    "& button": {
      color: "#0192be",
      borderColor: "#0192be",
      transition:
        "color 0.5s ease-in-out, borderColor 0.5s ease-in-out, background 0.5s ease-in-out",
      "&:hover": {
        color: "#fff",
        borderColor: "#0192be",
        background: "#0192be",
      },
    },
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      flexContainer: {
        justifyContent: "start !important",
      },
    },
    MuiPaginationItem: {
      page: {
        "&.Mui-selected": {
          color: "#fff",
          backgroundColor: "#FFF",
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        display: "initial",
        margin: "0px 0px !important",
        padding: "5px 0px !important",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "16px",
        fontWeight: "500",
        color: "#000",
        // textAlign: "justify",
        fontFamily: "system-ui",
      },
      h3: {
        fontFamily: "system-ui",
        fontSize: "16px",
        lineHeight: "40px",
        fontWeight: "500",
      },
    },
    MuiAccordionDetails: {
      root: {
        display: "initial",
        padding: "0 0 10px 0",
      },
    },
    MuiAccordion: {
      root: {
        "&::before": {
          background: "none !important",
        },
      },
    },
  },
});

export default function Acrchieve({ CROP_HEALTH }) {
  const classes = useStyles();
  const [displayCount, setDisplayCount] = useState(8);

  const increaseDisplay = () => {
    setDisplayCount((prevCount) => prevCount + 5);
  };

  const openPdfFunc = (Pdf_url) => {
    NormalPdfOpen(Pdf_url);
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Box className={classes.AcchivTitle} mb={2}>
          <Box className={classes.leftContent}>
            <Typography variant="h3" className={classes.ListtopTitle}>
              {localeFunction("Crop Health Bulletin")}
            </Typography>
          </Box>

          <Box className={classes.rightContent}>
            <Typography variant="h3" className={classes.ListtopTitle}>
              {localeFunction("Annexure")}
            </Typography>
          </Box>
        </Box>

        <Box>
          {CROP_HEALTH.slice(0, displayCount).map((data, index) => {
            return (
              <Box
                key={index}
                elevation={0}
                className={classes.arciveListTitle}
              >
                <Box className={classes.AcchivTitle}>
                  <Box>
                    <Box
                      className={classes.underlineText}
                      onClick={() => openPdfFunc(data?.main_pdf)}
                    >
                      <IconButton>
                        <LibraryBooksIcon style={{ fontSize: "18px" }} />
                      </IconButton>

                      <Typography variant="h3">{data?.title}</Typography>
                    </Box>
                  </Box>

                  <Box className={classes.statedata}>
                    {data?.items?.map((items, index) => {
                      return (
                        <>
                          <Box width={"100px"} key={index}>
                            <Box
                              className={classes.underlineText}
                              onClick={() => openPdfFunc(items?.inner_document)}
                            >
                              <Typography variant="h3">
                                {items?.inner_title}
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            );
          })}

          {displayCount >= CROP_HEALTH?.length ? (
            ""
          ) : (
            <Box align="center" mt={3} mb={3} className={classes.viewmoreBtn}>
              <Button variant="outlinedPrimary" onClick={increaseDisplay}>
                {localeFunction("View_more")}
              </Button>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </div>
  );
}
