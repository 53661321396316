import React from "react";
import Box from "@material-ui/core/Box";
import InputBase from "@material-ui/core/InputBase";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { localeFunction } from "../utils";
// impoer localeFunction
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    backgroundColor: "#FFF",
    // "&:hover": {
    //   backgroundColor: "#FFF",
    // },
    marginLeft: 0,
    width: "40%",
    "@media(max-width:1280px)": {
      width: "50%",
    },
    "@media(max-width:425px)": {
      width: "100%",
    },
    "@media(max-width:768px)": {
      width: "100%",
    },
  },
  searchIcon: {
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },
  inputRoot: {
    color: "inherit",
    fontWeight: 400,
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(0)}px)`,
    color: "#000",
    "&::placeholder": {
      color: "#000",
      fontWeight: 400,
    },
    [theme.breakpoints.up("sm")]: {
      paddingRight: "10px",
    },
  },
}));

export default function SearchbarField({ searchuserData, handleKeyPress }) {
  const classes = useStyles();

  return (
    <Box className={classes.search}>
      <InputBase
        placeholder={localeFunction("search") + "..."}
        onChange={(event) => searchuserData(event.target.value)}
        onKeyPress={handleKeyPress}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </Box>
  );
}
