import React, { useContext, useState } from "react";
import {
  makeStyles,
  Box,
  Card,
  Typography,
  IconButton,
} from "@material-ui/core";

import { FiEdit } from "react-icons/fi";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import { localeFunction } from "../../utils";
import ProfileImgUpdateDialog from "./ProfileImgUpdateDialog";
import ProfileImg from "./ProfileImg";
import EmailIcon from "@material-ui/icons/Email";
import { PostApiFunction } from "../../utils";
import Apiconfigs from "../../ApiConfig/Apiconfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import
import { AuthContext } from "../../context/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "0px",
    paddingBottom: "10px",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: "4px solid #FFF",
    borderRadius: "100%",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "100%",
    },
  },
  cardHeader: {
    width: "100%",
    background: "#23262f",

    "& .img_box": {
      height: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",

      "& h6": {
        color: "#FFFF",
      },
    },

    "& .usernametitle": {
      "& h6": {
        marginTop: "5px",
      },
    },
  },

  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    padding: "5px 10px",
    "& .cardLefttext": {
      textAlign: "left",
      width: "40%",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      "& h6": {
        lineHeight: "20px",
        fontWeight: 500,
        "@media(max-width:599px)": {
          fontSize: "13px",
        },
      },
    },
    "& .cardRightContent": {
      textAlign: "left",
      width: "60%",
      "& h6": {
        lineHeight: "20px",
        "@media(max-width:1280px)": {
          fontSize: "14px",
        },
        "@media(max-width:599px)": {
          fontSize: "13px",
        },
      },
    },
  },
  cardIcon: {
    width: "34px",
    height: "34px",
    color: "#FFF",
    // background: "#06485b",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      width: "60%",
      height: "60%",
    },
    "& h6": {
      cursor: "pointer !important",
    },
  },
  mainPopBoxCard: {
    "@media(max-width:375px)": {
      paddingRight: "5px",
    },
  },
}));

export default function Profile_card({ handleClosebtn, profileData }) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [_isloading, setIsLoading] = useState(false);
  console.log("876578--->", auth?.GetProfileFunction);
  console.log("profileData", profileData);

  const handleOpen = () => {
    setOpen(true);
    setSelectedFile(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedFile, setSelectedFile] = useState("");

  const handleFileChange = (e) => {
    if (e.target.value !== "") {
      const file = e.target.files[0];
      const limit = 1.5 * 1024 * 1024;
      const fileSize = file.size;
      setSelectedFile(file);
    }

    // if (file) {
    //   if (fileSize > limit) {
    //     alert("File size maximum 2 MB");
    //     return;
    //   }

    //   const reader = new FileReader();
    //   reader.onload = function (e) {
    //     const img = new Image();
    //     setSelectedFile(file);

    //     // img.onload = function () {
    //     //   if (
    //     //     img.width >= 200 &&
    //     //     img.height >= 200 &&
    //     //     img.width <= 400 &&
    //     //     img.height <= 400
    //     //   ) {
    //     //     setSelectedFile(file);
    //     //   } else {
    //     //     alert(
    //     //       "Please upload an image with dimensions between 200x200 and 400x400 pixels."
    //     //     );
    //     //   }
    //     // };
    //     // img.onerror = function () {
    //     //   alert("Error loading image");
    //     // };
    //     // img.src = e.target.result;
    //   };
    //   reader.onerror = function () {
    //     alert("Error reading file");
    //   };
    //   reader.readAsDataURL(file);
    // } else {
    //   alert("File is not selected");
    //   return;
    // }
  };

  const profileDetails = [
    {
      title: localeFunction("Employee_code"),
      icon: "/images/profileicon/Employe-Code.png",
      details: `${profileData?.employee_id}`,
      marg: "",
    },

    {
      title: localeFunction("Email"),
      icon: "",
      details: `${profileData?.user_email}`,
      marg: "",
    },
    {
      title: localeFunction("Designation"),
      icon: "/images/profileicon/Designation.png",
      details: `${profileData?.designation}`,
      marg: "",
    },
    {
      title: localeFunction("Office_Location"),
      icon: "/images/profileicon/Office-Location.png",
      details: `${profileData?.ro_name}`,
      marg: "",
    },
    {
      title: localeFunction("Mobile"),
      icon: "/images/profileicon/Mobile.png",
      details: `${profileData?.mobile}`,
      marg: "",
    },
  ];

  const UpdateProfile = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("emp_id", profileData?.employee_id);

      const res = await PostApiFunction({
        endPoint: Apiconfigs.upload_emp_image,
        data: formData,
      });
      if (res) {
        setIsLoading(false);

        toast.success("Profile image uploaded successfully");
        setOpen(false);
        auth?.GetProfileFunction();
        setSelectedFile("");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  return (
    <>
      <ToastContainer />

      <Box align={"center"} id="style-9" className="profileScrollbar">
        <Card className={classes.root} id="style-3">
          <Box className={classes.cardHeader}>
            <Box textAlign={"right"}>
              <IconButton onClick={handleClosebtn}>
                <CloseIcon style={{ color: "#FFF" }} />
              </IconButton>
            </Box>

            <Box className="img_box">
              <Box className={classes.large}>
                <ProfileImg
                  image={profileData?.image}
                  selectedFile={selectedFile}
                />
              </Box>
              <Box className="usernametitle">
                <Typography variant="h6">{profileData?.name}</Typography>
              </Box>

              <Tooltip
                title={
                  <span
                    style={{
                      fontSize: "15px",
                      padding: "1px",
                    }}
                  >
                    Update profile picture
                  </span>
                }
                arrow
                placement="right"
              >
                <IconButton onClick={handleOpen}>
                  <FiEdit style={{ color: "#FFF" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box mt={2} className={classes.mainPopBoxCard}>
            {profileDetails &&
              profileDetails?.map((data, index) => {
                if (data?.details !== "null") {
                  return (
                    <Box className={classes.cardContent} key={index}>
                      <Box
                        className="cardLefttext"
                        style={{ paddingBottom: `${data?.marg}` }}
                      >
                        <Box className={classes.cardIcon}>
                          {data?.icon === "" ? (
                            <EmailIcon style={{ color: "#817d7d" }} />
                          ) : (
                            <img src={data?.icon} alt="img" />
                          )}
                        </Box>
                        <Typography variant="h6">{data?.title}</Typography>
                      </Box>
                      :
                      <Box className="cardRightContent">
                        <Typography variant="h6">{data?.details}</Typography>
                      </Box>
                    </Box>
                  );
                } else {
                  return "";
                }
              })}
          </Box>
        </Card>
      </Box>
      <ProfileImgUpdateDialog
        UpdateProfile={UpdateProfile}
        open={open}
        handleClose={handleClose}
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
        _isloading={_isloading}
        profileData={profileData}
      />
    </>
  );
}
