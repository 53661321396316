import React from "react";
import { Box, Tooltip, Typography, makeStyles } from "@material-ui/core";
import SearchbarField from "./SearchbarField";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  searchCompo: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    marginRight: "10px",
    "@media(max-width:1024px)": {
      display: "none",
    },
    "@media(max-width:425px)": {
      display: "none",
    },
    "& .search_Button": {
      background: "#06485b",
      height: "34px",
      borderRadius: "0px 5px 5px 0px",
      cursor: "pointer",
      width: "45px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& h6": {
        fontSize: "13px !important",
        padding: "5px",
      },
    },
  },
}));

const AnnoucementDesktopSearch = ({
  USerSearch,
  handleKeyPress,
  SearchAnnoucementHandle,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.searchCompo}>
      <SearchbarField
        searchuserData={USerSearch}
        handleKeyPress={handleKeyPress}
      />
      <Tooltip
        title={
          <Typography variant="h6" style={{ color: "#FFF", fontSize: "14px" }}>
            search
          </Typography>
        }
        placement="top"
        arrow
      >
        <Box
          className="search_Button"
          onClick={() => SearchAnnoucementHandle("e")}
        >
          <SearchIcon style={{ fontSize: "20px", color: "#FFF" }} />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default AnnoucementDesktopSearch;
