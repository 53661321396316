import React from "react";
import { Box, Typography, withStyles, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import { localeFunction } from "../../utils";
import { NormalPdfOpen } from "../../utils/sessionStorage";

const useStyles = makeStyles({
  ListProduct: {
    "& h3": {
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
  },
  mainTableBox: {
    "& h4": {
      marginTop: "10px",
      fontSize: "16px",
      fontWeight: "600",
      color: "#000",
    },
  },
  btnButton: {
    padding: "0 58px",
    marginTop: "21px",
    height: "45px",
    "&:hover": {
      backgroundColor: "rgba(0,125,108,1.0)",
    },
  },
  addressrow: {
    "@media(max-width:768px)": {
      minWidth: "280px",
    },
  },

  underlineText: {
    width: "100%",
    color: "#000",
    textDecoration: "none",
    transition: "0.5s ease-in-out",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer !important",
    gap: "15px",
    "& :hover": {
      textDecoration: "underline",
      color: "#0192be",
    },
    "& h6": {
      lineHeight: "23px",
      fontWeight: 400,
      fontFamily: "system-ui",
      width: "200px",
      padding: "10px 0px",
    },
    "& button": {
      width: "35px",
      height: "35px",
      color: "#FFF",
      background: "#06485b",
    },
  },
});

const Acrchieve = ({ _crophelthdoc }) => {
  const classes = useStyles();

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#0192be21",
      color: "#000",
      textAlign: "left",
      whiteSpace: "nowrap",
      fontWeight: "700",
      border: "1px solid #0192be21",
    },

    body: {
      fontSize: 14,
      fontWeight: "600",
      fontFamily: "system-ui",
      border: "1px solid #ccc",
    },
  }))(TableCell);

  const theme = createMuiTheme({
    overrides: {
      MuiTypography: {
        h6: {
          fontSize: "16px",
          fontWeight: "400",
          color: "#000",
          fontFamily: "system-ui",
        },
      },
      MuiPaginationItem: {
        page: {
          "&.Mui-selected": {
            color: "#fff",
            backgroundColor: "#0192be",
          },
        },
      },
      MuiTableContainer: {
        root: {
          overflow: "initial",
          boxShadow: "none",
        },
      },
      MuiTableRow: {
        root: {
          "&:hover": {
            backgroundColor: "#50853d14",
          },
        },
        //   .MuiTableRow-root-288
      },
    },
  });

  const openPdfFunc = (Pdf_url) => {
    NormalPdfOpen(Pdf_url);
  };

  return (
    <Box
      className={classes.mainTableBox}
      style={{
        paddingBottom: "20px",
      }}
    >
      <ThemeProvider theme={theme}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" style={{ width: "50px" }}>
                  {localeFunction("Crop Health Bulletin")}
                </StyledTableCell>
                <StyledTableCell align="right" colSpan={8}>
                  <Box display={"flex"} alignItems={"center"}>
                    <span>{localeFunction("Annexure")}</span>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody style={{ borderBottom: "1px solid #ccc" }}>
              <Box>
                {_crophelthdoc &&
                  _crophelthdoc?.map((data, index) => {
                    return (
                      <Box
                        key={index}
                        style={{
                          borderRadius: "0px",
                          textAlign: "left",
                          boxShadow: "none",
                          padding: "10px 0px 10px 10px",
                          borderRight: "1px solid #ccc",
                          borderLeft: "1px solid #ccc",
                        }}
                        elevation={0}
                      >
                        <Box className={classes.AcchivTitle}>
                          <Box>
                            <Box
                              className={classes.underlineText}
                              onClick={() => openPdfFunc(data?.main_pdf)}
                            >
                              <Typography variant="h6">
                                {data?.title}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className={classes.statedata}>
                            {data?.items?.map((items, index) => {
                              return (
                                <>
                                  <Box width={"100px"} key={index}>
                                    <Box
                                      className={classes.underlineText}
                                      onClick={() =>
                                        openPdfFunc(items?.inner_document)
                                      }
                                    >
                                      <Typography variant="h3">
                                        {items?.inner_title}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </Box>
  );
};

export default Acrchieve;
